import React from 'react';
import { Space } from 'antd';
import Button from '../button/Button';
import Modal from './Modal';
import Typography from '../typography/Typography';
import { spacing } from '../theme/theme';
import { TrackEventHandler } from '../types';

export interface ConfirmModalProps {
  onCancel: () => void;
  onOkay: () => void;
  heading: string;
  body?: React.ReactNode;
  cancelButtonText: string;
  okayButtonText: string;
  okayInProgress?: boolean;
  trackEvent?: TrackEventHandler;
  size?: 's' | 'm';
  variant?: 'delete' | 'confirm';
  closable?: boolean;
  testId?: string;
}

const ConfirmModal: React.FC<React.PropsWithChildren<ConfirmModalProps>> = ({
  okayInProgress = false,
  onCancel,
  onOkay,
  heading,
  body,
  cancelButtonText,
  okayButtonText,
  trackEvent,
  size = 'm',
  variant = 'confirm',
  closable = true,
  testId = `${variant}-modal`,
}: ConfirmModalProps) => {
  return (
    <Modal
      open
      size={size}
      headerSize={'m'}
      onCancel={onCancel}
      variant="marketing"
      headerText={heading}
      testId={testId}
      trackEvent={trackEvent}
      closable={closable}
    >
      <Space
        direction="vertical"
        size={spacing.space24}
        className="text-align-c flex-align-c"
      >
        {body && (
          <Typography size="s" type="body" color="secondary7">
            {body}
          </Typography>
        )}
        <Space>
          <Button
            data-testid={`${testId}-btn-cancel`}
            key="back"
            type="secondary"
            variant="outline"
            shape="round"
            size="m"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCancel?.();
            }}
            trackEvent={trackEvent}
          >
            {cancelButtonText}
          </Button>
          <Button
            data-testid={`${testId}-btn-confirm`}
            key="submit"
            type={variant === 'delete' ? 'danger' : 'primary'}
            variant="filled"
            shape="round"
            size="m"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onOkay?.();
            }}
            loading={okayInProgress}
            trackEvent={trackEvent}
          >
            {okayButtonText}
          </Button>
        </Space>
      </Space>
    </Modal>
  );
};

export default ConfirmModal;
